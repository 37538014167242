import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, of, switchMap } from 'rxjs';

import { CurrentSiteState } from '@ppg/configuration';
import { EnvVarsNames } from '@ppg/core/enums';
import { EnvironmentService } from '@ppg/core/env-provider';
import { LanguagesService } from '@ppg/core/language';
import { LoggerService } from '@ppg/core/logger';
import {
  ColorFamilyDataModel,
  ColorInfoDataModel,
  ColorCategoryTypeDataModel,
  ColorCollectionDataModel,
  GetColorFamiliesResponseDataModel,
} from '@ppg/core/models';
import { ProductApiSettingsService } from '@ppg/shared/product-api';

@Injectable({ providedIn: 'root' })
export class ColorInfoApiService {
  private readonly currentSiteState = inject(CurrentSiteState);
  private readonly http = inject(HttpClient);
  private readonly environmentService = inject(EnvironmentService);
  private readonly loggerService = inject(LoggerService);
  private readonly languagesService = inject(LanguagesService);
  private readonly productApiSettingsService = inject(ProductApiSettingsService);

  get productApiLanguage() {
    const curentLanguage = this.languagesService.language();

    return (
      this.productApiConfig.languageCode.find((x) => x === curentLanguage) ??
      this.productApiConfig.languageCode.find((x) => x == this.currentSiteState.defaultLanguage()) ??
      this.productApiConfig.languageCode[0]
    );
  }

  getColorInfo(productNumbers: string[]): Observable<ColorInfoDataModel[]> {
    return this.http
      .post<ColorInfoDataModel[]>(
        `${this.apiUrl}/assortment/${this.productApiConfig.assortmentId}/products`,
        this.colorInfoBody,
        {
          headers: this.httpHeaders,
          params: {
            $filter: `${this.createProductNumbersFilter(productNumbers)} and ${this.createAvailabilityDateFilter()}`,
          },
        },
      )
      .pipe(
        map((colors: ColorInfoDataModel[] | null) => colors ?? []),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`Error occurred while fetching colors: ${e.message}.`);
          return of([] as ColorInfoDataModel[]);
        }),
      );
  }

  getColorDetails(productNumber: string): Observable<ColorInfoDataModel | null> {
    return this.getColorInfo([productNumber]).pipe(map((colors) => colors[0] ?? null));
  }

  getColorFamilies(colorFamilyTypeNames: string[]): Observable<GetColorFamiliesResponseDataModel> {
    return this.http
      .post<ColorCategoryTypeDataModel[]>(
        `${this.apiUrl}/webhierarchy/${this.productApiConfig.webHierarchy}/categories`,
        this.colorCategoryBody,
        {
          headers: this.httpHeaders,
          params: {
            $filter: this.createColorFamilyTypeIdentifiersFilter(this.productApiLanguage, colorFamilyTypeNames),
          },
        },
      )
      .pipe(
        map((colorFamilyTypes: ColorCategoryTypeDataModel[] | null) => colorFamilyTypes ?? []),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`Error occurred while fetching color families identifiers: ${e.message}.`);
          return of([] as ColorCategoryTypeDataModel[]);
        }),
        switchMap((colorFamilyTypes: ColorCategoryTypeDataModel[]) =>
          this.http
            .post<ColorFamilyDataModel[]>(
              `${this.apiUrl}/webhierarchy/${this.productApiConfig.webHierarchy}/categories`,
              this.colorCategoryBody,
              {
                headers: this.httpHeaders,
                params: {
                  $filter: `${this.createFilterByTypes(colorFamilyTypes)} and ${this.createAvailabilityDateFilter()}`,
                },
              },
            )
            .pipe(
              map((colorFamilies: ColorFamilyDataModel[] | null) => {
                return {
                  colorFamilyTypes: colorFamilyTypes,
                  colorFamilies: colorFamilies ?? [],
                } as GetColorFamiliesResponseDataModel;
              }),
              catchError((e: HttpErrorResponse) => {
                this.loggerService.error(`Error occurred while fetching color families: ${e.message}.`);
                return of({
                  colorFamilyTypes: [],
                  colorFamilies: [],
                } as GetColorFamiliesResponseDataModel);
              }),
            ),
        ),
      );
  }

  getColorCollections(): Observable<ColorCollectionDataModel[]> {
    return this.http
      .post<ColorCategoryTypeDataModel[]>(
        `${this.apiUrl}/webhierarchy/${this.productApiConfig.webHierarchy}/categories`,
        this.colorCategoryBody,
        {
          headers: this.httpHeaders,
          params: {
            $filter: this.createColorCollectionsIdentifierFilter(this.productApiLanguage),
          },
        },
      )
      .pipe(
        map((colorCollectionTypes: ColorCategoryTypeDataModel[] | null) => colorCollectionTypes ?? []),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`Error occurred while fetching color collections types: ${e.message}.`);
          return of([] as ColorCategoryTypeDataModel[]);
        }),
        switchMap((colorCollectionTypes: ColorCategoryTypeDataModel[]) =>
          this.http
            .post<ColorCollectionDataModel[]>(
              `${this.apiUrl}/webhierarchy/${this.productApiConfig.webHierarchy}/categories`,
              this.colorCategoryBody,
              {
                headers: this.httpHeaders,
                params: {
                  $filter: `${this.createFilterByTypes(colorCollectionTypes)} and ${this.createAvailabilityDateFilter()}`,
                },
              },
            )
            .pipe(
              map((colorCollections: ColorCollectionDataModel[] | null) => colorCollections ?? []),
              catchError((e: HttpErrorResponse) => {
                this.loggerService.error(`Error occurred while fetching color collections: ${e.message}.`);
                return of([] as ColorCollectionDataModel[]);
              }),
            ),
        ),
      );
  }

  getColorsPerCategory(categoryId: string): Observable<ColorInfoDataModel[]> {
    return this.http
      .post<ColorInfoDataModel[]>(
        `${this.apiUrl}/assortment/${this.productApiConfig.assortmentId}/products`,
        this.colorInfoBody,
        {
          headers: this.httpHeaders,
          params: {
            $filter: `${this.createColorsPerCategoryFilter(categoryId)} and ${this.createAvailabilityDateFilter()}`,
          },
        },
      )
      .pipe(
        map((colors: ColorInfoDataModel[] | null) => colors ?? []),
        catchError((e: HttpErrorResponse) => {
          this.loggerService.error(`Error occurred while fetching color families: ${e.message}.`);
          return of([] as ColorInfoDataModel[]);
        }),
      );
  }

  private get httpHeaders() {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('subscription-key', this.subscriptionKey)
      .append('x-api-key', this.apiKey);
  }

  private get apiUrl() {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_BASE_URL_V2)}`;
  }

  private get subscriptionKey() {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_SUBSCRIPTION_KEY)}`;
  }

  private get apiKey() {
    return `${this.environmentService.getEnvironmentVariable(EnvVarsNames.APIM_PRODUCT_API_API_KEY)}`;
  }

  private get productApiConfig() {
    const config = this.productApiSettingsService.colorJourneySettings();

    if (!config) {
      throw new Error('Color journey settings not found');
    }

    return config;
  }

  private get colorCategoryBody() {
    return JSON.stringify({
      channel: `${this.productApiConfig.channel}`,
      salesOrganization: `${this.productApiConfig.salesOrganization}`,
      media: [], // passing an empty array will result in quering for all media assets
      languages: [this.productApiLanguage],
    });
  }

  private get colorInfoBody() {
    return JSON.stringify({
      channel: `${this.productApiConfig.channel}`,
      salesOrganization: `${this.productApiConfig.salesOrganization}`,
      webHierarchy: `${this.productApiConfig.webHierarchy}`,
      media: [], // passing an empty array will result in quering for all media assets
      languages: [this.productApiLanguage],
    });
  }

  private createProductNumbersFilter(productNumbers: string[]) {
    return `productNumber  in ('${productNumbers.join("','")}')`;
  }

  private createColorsPerCategoryFilter(categoryId: string) {
    return `categoryIds/any(catId: contains(catId , '${categoryId}'))`;
  }

  private createColorFamilyTypeIdentifiersFilter(language: string, colorFamilyTypeNames: string[]) {
    return colorFamilyTypeNames
      .map((typeName) => `name/any(n: n/language eq '${language}' and n/translation eq '${typeName}')`)
      .join(' or ');
  }

  private createColorCollectionsIdentifierFilter(language: string) {
    return `name/any(n: n/language eq '${language}' and n/translation eq 'Color Collections')`;
  }

  private createFilterByTypes(groupIdentifiers: ColorCategoryTypeDataModel[]) {
    return `nodeType eq 'Leaf' and parentId in ('${groupIdentifiers.map((x) => x.identifier).join("','")}')`;
  }

  private createAvailabilityDateFilter() {
    return `statusData/availabilityStartDate le ${this.currentDateUTC} and statusData/availabilityEndDate ge ${this.currentDateUTC}`;
  }

  private get currentDateUTC() {
    return new Date().toISOString().split('T')[0];
  }
}
