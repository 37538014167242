import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ResolveFn } from '@angular/router';
import { catchError, map, of, switchMap } from 'rxjs';

import { Category } from '../../models';
import { ProductApiSettingsService } from '../../services/product-api-settings/product-api-settings.service';
import { ProductApiService } from '../../services/product-api.service';

export const categoryResolver: ResolveFn<Category[]> = () => {
  const productApiService = inject(ProductApiService);
  const productApiSettingsService = inject(ProductApiSettingsService);

  return toObservable(productApiSettingsService.productJourneySettings).pipe(
    map((settings) =>
      settings
        ? {
            webHierarchy: settings.webHierarchy,
            channel: settings.channel,
            language: productApiSettingsService.standardCultureLanguage()!,
            salesOrganization: settings.salesOrganization,
          }
        : null,
    ),
    switchMap((params) => {
      if (!params) {
        return of([]);
      }

      const { webHierarchy, ...restParams } = params;
      const odata = '$select=Identifier,Id,Name,WebHierarchyLevel,ParentId&$expand=Name';
      return productApiService
        .getCategories(webHierarchy, restParams, odata)
        .pipe(catchError(() => of([] as Category[])));
    }),
  );
};
